import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// styles
import "./App.scss";
import "./styles/images.scss";
import "./styles/inputs.scss";
import "./styles/card.scss";
import "./styles/bootstrap-overwrite.scss";
import "./styles/container.scss";
import "./styles/buttons.scss";
import "./styles/animation.scss";
import "./styles/text.scss";

import axios from "axios";
import PrivateRoute from "./components/PrivateRoutes";

/* Layouts */
const DefaultLayout = lazy(() => import("./layouts/default-layout.js"));
const LandingLayout = lazy(() => import("./layouts/landing-layout"));
const FocusLayout = lazy(() => import("./layouts/focus-layout"));

// Pages
const Landing = lazy(() => import("./pages/landing/landing.js"));
const LandingStudents = lazy(() => import("./pages/landing/landing-students"));
const Register = lazy(() => import("./pages/auth/Register"));
const Profile = lazy(() => import("./pages/Profile"));

const CGU = lazy(() => import("./pages/footer/CGU"));
const CGV = lazy(() => import("./pages/footer/CGV"));
const Story = lazy(() => import("./pages/footer/Story"));

const Lessons = lazy(() => import("./pages/liste-des-qcms/Lessons"));
const Lesson = lazy(() => import("./pages/qcm/Lesson"));

const Forum = lazy(() => import("./pages/forum-discussion/Forum"));
const Discussion = lazy(() => import("./pages/forum-discussion/Discussion"));

const AllComponents = lazy(() => import("./pages/AllComponents"));
const Decks = lazy(() => import("./pages/revision/Decks"));
const MemoryCard = lazy(() => import("./pages/revision/MemoryCard"));

const CreateQuestions = lazy(() =>
  import("./pages/manage-lessons/CreateQuestions")
);
const ManageAccounts = lazy(() =>
  import("./pages/manage-accounts/ManageAccounts")
);
const AdminAnalytics = lazy(() =>
  import("./pages/admin-analytics/AdminAnalytics")
);

const Exam = lazy(() => import("./pages/qcm/exam/Exam.js"));

const Support = lazy(() => import("./pages/support-information/Support"));
const Settings = lazy(() => import("./pages/settings/Settings"));

import { NotFoundPage } from "./pages/NotFoundPage";

import CacheBuster from "./components/CacheBuster";
//import SpinnerLoading from "./components/SpinnerLoading/SpinnerLoading";
import SpinnerLoadingFullPage from "./components/SpinnerLoading/SpinnerLoadingFullPage";

import "./styles/position.scss";
import Unauthorized from "./pages/unauthorized/Unauthorized";
import AdminRoutes from "./components/AdminRoutes";
import NavigationLayout from "./layouts/navigation-layout";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.baseURL =
  // "http://localhost:5000/school-upgreat-pass/europe-west1/api";
  "https://europe-west1-school-upgreat-pass.cloudfunctions.net/api";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPage: true,
      authorized: false,
    };
  }
  // Arrow fx for binding
  setLoadingPage = (loading) => {
    this.setState({ loadingPage: loading });
  };

  setAuthorized = () => {
    this.setState({ authorized: true });
  };
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <>
              <Router>
                <div className="App">
                  {window.innerWidth > 200 || this.state.authorized === true ? (
                    <Suspense
                      fallback={<SpinnerLoadingFullPage />}
                      className="position_center"
                    >
                      <Switch className="container">
                        <Route
                          exact
                          path="/"
                          component={() => (
                            <LandingLayout child={<LandingStudents />} />
                          )}
                        />
                        <Route
                          exact
                          path="/login"
                          component={() => (
                            <DefaultLayout child={<Landing />} />
                          )}
                        />
                        <Route
                          exact
                          path="/unauthorized"
                          component={() => (
                            <DefaultLayout child={<Unauthorized />} />
                          )}
                        />
                        <Route path="/404" component={NotFoundPage} />

                        <Route
                          path="/support-information"
                          component={() => (
                            <DefaultLayout child={<Support />} />
                          )}
                        />

                        <Route
                          exact
                          path="/register"
                          component={() => (
                            <DefaultLayout child={<Register />} />
                          )}
                        />

                        <Route
                          exact
                          path="/demo"
                          component={() => (
                            <DefaultLayout child={<CreateLessons />} />
                          )}
                        />

                        <Route
                          exact
                          path="/demo/EditTemplate"
                          component={() => (
                            <DefaultLayout child={<EditTemplate />} />
                          )}
                        />

                        <Route
                          exact
                          path="/create"
                          component={() => (
                            <DefaultLayout child={<CreateLessons />} />
                          )}
                        />

                        <Route
                          exact
                          path="/lessons"
                          component={() => (
                            <DefaultLayout child={<Lessons />} />
                          )}
                        />

                        <Route
                          exact
                          path="/lessons/:idCourse/:idChapter/:idFragment"
                          component={this.LessonRoute}
                        />
                        <Route
                          exact
                          path="/exams/:idCourse/:idChapter/:idFragment"
                          component={this.ExamRoute}
                        />
                        <PrivateRoute
                          exact
                          path="/forum"
                          component={() => <DefaultLayout child={<Forum />} />}
                        />
                        <PrivateRoute
                          exact
                          path="/forum/:id"
                          component={() => (
                            <DefaultLayout child={<Discussion />} />
                          )}
                        />
                        <Route
                          exact
                          path="/story"
                          component={() => <DefaultLayout child={<Story />} />}
                        />
                        <Route
                          exact
                          path="/cgu"
                          component={() => <DefaultLayout child={<CGU />} />}
                        />
                        <Route
                          exact
                          path="/cgv"
                          component={() => <DefaultLayout child={<CGV />} />}
                        />
                        <Route
                          exact
                          path="/AllComponents"
                          component={() => (
                            <DefaultLayout child={<AllComponents />} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/Decks"
                          component={() => <DefaultLayout child={<Decks />} />}
                        />
                        <PrivateRoute
                          exact
                          path="/Decks/:id"
                          component={() => (
                            <DefaultLayout child={<MemoryCard />} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/Modifications"
                          component={() => (
                            <DefaultLayout child={<ModifCours />} />
                          )}
                        />

                        <AdminRoutes
                          exact
                          path="/admin"
                          component={() => (
                            <DefaultLayout child={<CreateQuestions />} />
                          )}
                        />
                        <AdminRoutes
                          exact
                          path="/admin-accounts"
                          component={() => (
                            <DefaultLayout child={<ManageAccounts />} />
                          )}
                        />
                        <AdminRoutes
                          exact
                          path="/admin-analytics"
                          component={() => (
                            <DefaultLayout child={<AdminAnalytics />} />
                          )}
                        />
                      </Switch>
                    </Suspense>
                  ) : (
                    <NotFoundPage setAuthorized={this.setAuthorized} />
                  )}
                </div>
              </Router>
            </>
          );
        }}
      </CacheBuster>
    );
  }
  LessonRoute(props) {
    return (
      <FocusLayout
        child={
          <Lesson
            idCourse={props.match.params.idCourse}
            idChapter={props.match.params.idChapter}
            idFragment={props.match.params.idFragment}
          />
        }
      />
    );
  }
  ExamRoute(props) {
    return (
      <FocusLayout
        child={
          <Exam
            idCourse={props.match.params.idCourse}
            idChapter={props.match.params.idChapter}
            idFragment={props.match.params.idFragment}
          />
        }
      />
    );
  }
}

export default App;
