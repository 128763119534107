/**
 *
 * PrivateRoutes
 *
 */
import React, { useState } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";

//REDUX
import { connect } from "react-redux";
import { isConnected } from "../redux/actions/userActions";
import Spinner from "./SpinnerLoading/SpinnerLoading";

const PrivateRoutes = ({ component: Component, ...rest }) => {
  const [bool, setBool] = useState(true);
  const [loading, setLoading] = useState(true);

  rest.isConnected().then((res) => {
    setBool(res);
    setLoading(false);
  });

  return loading ? (
    <Spinner />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        bool ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = { isConnected };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(PrivateRoutes));
