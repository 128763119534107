import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";

import "../styles/navbar.scss";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
//REDUX
import { connect } from "react-redux";
import { loginUser } from "../redux/actions/userActions";

import PointdInterrogation from "../images/svg/point-dinterrogation.svg";
import Books from "../images/svg/books.svg";
import Logout from "../images/svg/logout.svg";
class Header extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    if (localStorage.usertoken) {
      this.props.loginUser();
    }
  }
  // Arrow fx for binding
  logOut = (e) => {
    // e.preventDefault();
    localStorage.removeItem("usertoken");
    delete axios.defaults.headers.common["Authorization"];
    this.props.history.push("/");
    // clear cache
    document.location.reload(true);
  };

  render() {
    return (
      <Row style={{ justifyContent: "end" }} className="py-5 ">
        {/* <SearchBar
          searchTerm={searchTerm}
          school={school}
          level={level}
          onSearchTermChange={this.handleSearchTermChange}
          onSchoolChange={this.handleSchoolChange}
          onLevelChange={this.handleLevelChange}
        /> */}

        <Col lg={3} xs={8} className="position_center my-2 text-align-end">
          <Stack
            className="mb-2 w-100 d-flex justify-content-around"
            direction="horizontal"
            gap={5}
          >
            <React.Fragment>
              <Link
                to="/support-information"
                className="mx-2 text-dark position_center"
              >
                {/* <FontAwesomeIcon
                    icon={faQuestion}
                    className="grow grey"
                    style={{ width: 25, height: 25 }}
                  /> */}
                <img
                  src={PointdInterrogation}
                  className=" grow"
                  width="30px"
                  height="30px"
                />
              </Link>

              <Link
                to="/"
                className="text-dark position_center text-hover mx-2"
                onClick={(e) => this.logOut(e)}
              >
                <img
                  src={Logout}
                  className=" grow"
                  width="27px"
                  height="27px"
                />
              </Link>
            </React.Fragment>
          </Stack>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  loginUser,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Header));
