import {
  SET_UNAUTHENTICATED,
  SET_USER,
  SET_COURS,
  CLEAN_COURS,
  SUPPRIME_QCM,
  SUPPRIME_COURS,
  SET_XP,
  GIVE_LVL,
  SET_IMAGE_PROFILE,
  SET_PSEUDO,
  SET_SAVEQUESTION,
  SET_ERRORQUESTION,
} from "../types";
import axios from "axios";

import store from "../store";

export const loginUser = () => async (dispatch) => {
  await axios
    .get("/users/authenticatedUser")
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data.data,
      });
      // if (res.data.data.coursesCreated) {
      //   dispatch({ type: CLEAN_COURS });
      //   res.data.data.coursesCreated.forEach((id) => {
      //     // Apparement inutilisé dans les warnings : const data = { idCours: id };
      //     axios.get(`/courses/${id}`).then((res) => {
      //       dispatch({
      //         type: SET_COURS,
      //         payload: res.data,
      //       });
      //     });
      //   });
      // }
    })
    .catch((err) => {
      return err;
    });
};

export const isConnected = () => async (dispatch) => {
  if (localStorage.usertoken) {
    var token = localStorage.usertoken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    const response = await axios.post(
      "/verify",
      {},
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    if (!response.data) {
      localStorage.removeItem("usertoken");
      delete axios.defaults.headers.common["Authorization"];
    } else {
      if (!store.getState().user.authenticated) {
        axios
          .get("/users/authenticatedUser")
          .then((res) => {
            dispatch({
              type: SET_USER,
              payload: res.data.data,
            });
          })
          .catch((err) => {
            return err;
          });
      }

      // await axios
      //   .get("/users/authenticatedUser")
      //   .then((res) => {
      //     dispatch({
      //       type: SET_USER,
      //       payload: res.data.data,
      //     });
      //   })
      //   .catch((err) => {
      //     return err;
      //   });
    }
    return response.data;
  }
  return false;
};

// const getCours = (id) => async (dispatch) => {
//   console.log("id");
//   const data = { idCours: id };
//   await axios.post("/showUnCours", data).then((res) => {
//     dispatch({
//       type: SET_COURS,
//       payload: res.data,
//     });
//   });
// };
export const suppQCM = (ind, ind_) => async (dispatch) => {
  dispatch({
    type: SUPPRIME_QCM,
    ind_: ind_,
    ind: ind,
  });
};
export const suppCours = (ind) => async (dispatch) => {
  dispatch({
    type: SUPPRIME_COURS,
    ind: ind,
  });
};

export const logOut = () => async (dispatch) => {
  dispatch({
    type: SET_UNAUTHENTICATED,
  });
};

export const setXp = (email, valueXp) => async (dispatch) => {
  const infoUpdate = {
    field: "xp",
    value: valueXp,
  };
  axios
    .patch(`users/updateUser/${email}`, { infoUpdate })
    .then(() => {
      dispatch({
        type: SET_XP,
        valueXp: valueXp,
      });
    })
    .catch((err) => {
      console.log("err: " + err);
    });
};

export const setXpForFunction = (dispatch, email, valueXp) => {
  const infoUpdate = {
    field: "xp",
    value: valueXp,
  };
  axios
    .patch(`users/updateUser/${email}`, { infoUpdate })
    .then(() => {
      dispatch({
        type: SET_XP,
        valueXp: valueXp,
      });
    })
    .catch((err) => {
      console.log("err: " + err);
    });
};

export const giveLvlRedux = () => async (dispatch) => {
  dispatch({
    type: GIVE_LVL,
    valueLvl: 1,
  });
};

export const uploadImage = (email, imageUrl) => (dispatch) => {
  console.log("upload Image");
  const infoUpdate = {
    field: "profileImage",
    value: imageUrl,
  };
  axios
    .patch(`users/updateUser/${email}`, { infoUpdate })
    .then(() => {
      dispatch({
        type: SET_IMAGE_PROFILE,
        payload: imageUrl,
      });
    })
    .catch((err) => {
      console.log("err: " + err);
    });
};

export const updatePseudo = (pseudo, email) => (dispatch) => {
  const infoUpdate = {
    field: "pseudo",
    value: pseudo,
  };
  axios
    .patch(`/users/updateUser/${email}`, { infoUpdate })
    .then(() => {
      dispatch({
        type: SET_PSEUDO,
        payload: pseudo,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const saveErrorQuestion = (questionsArrayUrl, email) => (dispatch) => {
  const infoUpdate = {
    field: "errorQuestions",
    value: questionsArrayUrl,
  };
  axios
    .patch(`/users/updateUser/${email}`, { infoUpdate })
    .then(() => {
      dispatch({
        type: SET_ERRORQUESTION,
        payload: questionsArrayUrl,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const saveQuestion = (questionsArrayUrl, email) => (dispatch) => {
  const infoUpdate = {
    field: "savedQuestions",
    value: questionsArrayUrl,
  };
  axios
    .patch(`/users/updateUser/${email}`, { infoUpdate })
    .then(() => {
      dispatch({
        type: SET_SAVEQUESTION,
        payload: questionsArrayUrl,
      });
      console.log("saveQuestion", questionsArrayUrl);
    })
    .catch((err) => {
      console.log(err);
    });
};
