import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons";

import { useSelector } from "react-redux";
import Star from "../../images/svg/star.svg";
import LogoStrasbourg from "../../images/tutorats/t2s-logo.webp";
import LogoParisSaclay from "../../images/tutorats/tsps-logo.webp";

import axios from "axios";
import SpinnerLoading from "../SpinnerLoading/SpinnerLoading";
const Leaderboard = () => {
  const [leaderboardUser, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (user.authenticated) {
      setLoading(true);
      axios
        .get(`/leaderboard/getUser/${user.data.establishment}`)
        .then((response) => {
          setLeaderboard(response.data.listUsers);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      setLeaderboard([
        {
          name: "Utilisateur 1",
          xp: "2000",
          profileImage: "/static/media/1_littleRidingHood.6fe39f32.svg",
        },
        {
          name: "Utilisateur 2",
          xp: "1500",
          profileImage: "/static/media/1_villager.3daaf8de.svg",
        },
        {
          name: "Utilisateur 3",
          xp: "1000",
          profileImage: "/static/media/1_worker.4c1ce4c6.svg",
        },
        {
          name: "Utilisateur 4",
          xp: "500",
          profileImage: "/static/media/3_gamer2.6944eff6.svg",
        },
      ]);
    }
  }, []);

  return (
    <Container className="stat_rounded_container px-3 py-4 my-5">
      <Row className="position_center mb-3">
        {user.data.establishment === "Strasbourg" && (
          <img
            width="80px"
            // height="400px"
            src={LogoStrasbourg}
            className="grow mx-3 fade-in"
            alt="screen create cours"
          />
        )}
        {user.data.establishment === "Paris-Saclay" && (
          <img
            width="80px"
            // height="400px"
            src={LogoParisSaclay}
            className="grow mx-3 fade-in"
            alt="screen create cours"
          />
        )}
      </Row>

      <Row className="position_center mb-2">
        <h4 className="mb-0 pb-0">Leaderboard</h4>
      </Row>
      {loading ? (
        <SpinnerLoading sm className="mx-auto w-100" />
      ) : (
        <Stack>
          {[
            leaderboardUser
              .slice(0, window.innerWidth < 400 ? 0 : 10)
              .map((userProfile, index) => (
                <Row
                  className="position_center flex-row my-2"
                  key={userProfile + index}
                >
                  <Col
                    lg={{ span: 1, order: 1 }}
                    xs={{ span: 4, order: 1 }}
                    className="bold"
                  >
                    <h5 className="mb-0">{index + 1}</h5>
                  </Col>
                  <Col lg={{ span: 2, order: 2 }} xs={{ span: 4, order: 2 }}>
                    {!!userProfile.profileImage ? (
                      <img
                        src={userProfile.profileImage}
                        style={{ width: "25px" }}
                        className="ml-2"
                      />
                    ) : (
                      <FontAwesomeIcon icon={faUser} size="2x" />
                    )}
                  </Col>
                  <Col
                    lg={{ span: 5, order: 3 }}
                    xs={{ span: 12, order: 4 }}
                    className="position_center"
                  >
                    {userProfile.pseudo !== undefined
                      ? userProfile.pseudo
                      : userProfile.name}
                  </Col>
                  <Col
                    lg={{ span: 4, order: 3 }}
                    xs={{ span: 4, order: 3 }}
                    className="position_center flex-row"
                  >
                    {userProfile.xp}
                    <img
                      src={Star}
                      style={{ width: "25px" }}
                      className="ml-2"
                    />
                  </Col>
                </Row>
              )),
          ]}
        </Stack>
      )}

      {user.authenticated && (
        <>
          <hr />
          <Row className="position_center flex-row my-2">
            <Col
              lg={{ span: 1, order: 1 }}
              xs={{ span: 4, order: 1 }}
              className="bold"
            >
              <h5 className="mb-0">
                {leaderboardUser.findIndex(
                  (element) => element.xp === user.data.xp
                ) + 1}
              </h5>
            </Col>
            <Col lg={{ span: 2, order: 2 }} xs={{ span: 4, order: 2 }}>
              {!!user.data.profileImage ? (
                <img
                  src={user.data.profileImage}
                  style={{ width: "25px" }}
                  className="ml-2"
                />
              ) : (
                <FontAwesomeIcon icon={faUser} size="2x" />
              )}
            </Col>
            <Col
              lg={{ span: 5, order: 3 }}
              xs={{ span: 12, order: 4 }}
              className="position_center"
            >
              {user.data.pseudo !== undefined
                ? user.data.pseudo
                : user.data.nom + " " + user.data.prenom}
            </Col>
            <Col
              lg={{ span: 4, order: 3 }}
              xs={{ span: 4, order: 3 }}
              className="position_center flex-row"
            >
              {user.data.xp}
              <img src={Star} style={{ width: "25px" }} className="ml-2" />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default Leaderboard;
