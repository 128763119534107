import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

export const NotFoundPage = ({ setAuthorized }) => {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link to={`/login`} style={{ textDecoration: "none" }}>
          <button
            className="btn_empty"
            onClick={() => {
              setAuthorized();
            }}
          >
            <h2 className="mb-3 mt-5 grow">Taille d'écran trop petite</h2>
          </button>
        </Link>
        <div className="logo_landing position_center my-5 mx-5 hover-shadow" />
        <p>
          L'application n'est pas adaptée à la taille d'écran. Essaye d'utiliser
          un écran d'ordinateur. Contacte schoolupgreat@gmail.com si ton
          problème persiste.
        </p>
      </div>
    </Container>
  );
};
