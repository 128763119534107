import {
  SET_USER,
  SET_UNAUTHENTICATED,
  SET_COURS,
  CLEAN_COURS,
  ADD_QCM,
  UPDATE_QCM,
  SUPPRIME_QCM,
  SUPPRIME_COURS,
  SUPPRIME_EXO,
  ADD_EXO,
  UPDATE_EXO,
  SET_XP,
  GIVE_LVL,
  SET_IMAGE_PROFILE,
  SET_PSEUDO,
  SET_SAVEQUESTION,
  SET_ERRORQUESTION,
} from "../types";

const initialState = {
  authenticated: false,
  data: [],
  cours: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        authenticated: true,
        data: action.payload,
      };
    case SUPPRIME_COURS:
      state.cours.splice(action.ind, 1);
      return {
        ...state,
      };
    case SUPPRIME_QCM:
      state.cours[action.ind].cours[1].splice(action.ind_, 1);
      return {
        ...state,
      };
    case SUPPRIME_EXO:
      state.cours[action.ind].cours[2].splice(action.ind_, 1);
      return {
        ...state,
      };
    case UPDATE_QCM:
      state.cours[action.ind].cours[1][action.ind_] = action.payload;
      return {
        ...state,
      };
    case UPDATE_EXO:
      state.cours[action.ind].cours[2][action.ind_] = action.payload;
      return {
        ...state,
      };
    case ADD_QCM:
      state.cours[action.ind].cours[1].push(action.payload);
      return {
        ...state,
      };
    case ADD_EXO:
      state.cours[action.ind].cours[2].push(action.payload);
      return {
        ...state,
      };
    case CLEAN_COURS:
      return {
        ...state,
        cours: [],
      };
    case SET_COURS:
      state.cours.push(action.payload);
      return { ...state };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_XP:
      state.data.xp = action.valueXp;
      return { ...state };
    case GIVE_LVL:
      state.data.level += action.valueLvl;
      return { ...state };
    case SET_IMAGE_PROFILE:
      state.data.profileImage = action.payload;
      return { ...state };
    case SET_PSEUDO:
      state.data.pseudo = action.payload;
      return { ...state };
    case SET_SAVEQUESTION:
      state.data.savedQuestions = action.payload;
      return { ...state };
    case SET_ERRORQUESTION:
      state.data.errorQuestions = action.payload;
      return { ...state };

    default:
      return state;
  }
}
