import React, { Component } from "react";
//import { Container } from "react-bootstrap";
import SpinnerLoading from "./SpinnerLoading";

const SpinnerLoadingFullPage = () => {
  return (
    <>
      {/* <Container
        fluid
        className="position_center"
        style={{
          backgroundColor: "white",
          height: "100vh",
          flexDirection: "column",
          position: "absolute",
          zIndex: "50",
        }}
      > */}
      <SpinnerLoading />
      {/* <h5 className="my-5">20 min de transport = 20 questions révisées</h5>
      </Container> */}
    </>
  );
};

export default SpinnerLoadingFullPage;
