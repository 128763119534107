// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const CLEAN_COURS = "CLEAN_COURS";
export const SET_COURS = "SET_COURS";
export const LOADING_USER = "LOADING_USER";
export const ADD_QCM = "ADD_QCM";
export const UPDATE_QCM = "UPDATE_QCM";
export const SUPPRIME_QCM = "SUPPRIME_QCM";
export const ADD_EXO = "ADD_EXO";
export const UPDATE_EXO = "UPDATE_EXO";
export const SUPPRIME_EXO = "SUPPRIME_EXO";
export const SUPPRIME_COURS = "SUPPRIME_COURS";
export const SET_XP = "SET_XP";
export const GIVE_LVL = "GIVE_LVL";
export const SET_IMAGE_PROFILE = "SET_IMAGE_PROFILE";
export const SET_PSEUDO = "SET_PSEUDO";
export const SET_SAVEQUESTION = "SET_SAVEQUESTION";
export const SET_ERRORQUESTION = "SET_ERRORQUESTION";
