import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";
const Unauthorized = () => {
  return (
    <Container className="px-5">
      <Row className="my-3 position_center">
        <Link to="/login">
          <button className="btn_primary blue">Menu</button>
        </Link>
      </Row>
      <Row className="mt-5 position_center">
        <h3>Vous n'avez pas les droits</h3>
        <p>
          Contactez votre bureau ou envoyez un mail à schoolupgreat@gmail.com si
          vous avez un problème.
        </p>
      </Row>
    </Container>
  );
};
export default Unauthorized;
