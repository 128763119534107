/**
 *
 * PrivateRoutes
 *
 */
import React, { useState } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";

//REDUX
import { connect } from "react-redux";
import { isConnected } from "../redux/actions/userActions";
import Spinner from "./SpinnerLoading/SpinnerLoading";

const PrivateRoutes = ({ component: Component, ...props }) => {
  const [connected, setConnected] = useState(true);
  const [admin, setAdmin] = useState(true);
  const [loading, setLoading] = useState(true);

  props.isConnected().then((res) => {
    setConnected(res);
    setAdmin(props.user.data.admin);
    setLoading(false);
  });

  return loading ? (
    <Spinner />
  ) : (
    <Route
      {...props}
      render={(props) =>
        connected && admin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/unauthorized",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = { isConnected };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(PrivateRoutes));
