import React from "react";

import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Strong from "../../images/svg/strong.svg";
import Pencil from "../../images/svg/pencil.svg";
import Brain from "../../images/svg/brain.svg";
import Ecole from "../../images/svg/earnTime.svg";
import Member from "../../images/svg/teamwork.svg";
import { useSelector } from "react-redux";

import adminEmailList from "../../data/admin.json";
const VerticalNavigation = ({
  setKey = () => console.log("setKey"),
  index = 0,
}) => {
  const user = useSelector((reduxState) => reduxState.user);
  return (
    <Container
      className="stat_rounded_container px-0 h-100 mt-5"
      style={{
        transform:
          window.innerWidth > 500 ? "translateX(-20px)" : "translateX(0)",
      }}
    >
      <Stack className="d-flex p-0 flex-column justify-content-around">
        <Button
          className={index === 0 ? "m-0 pl-3 active" : "m-0 pl-3"}
          variant="exo"
          onClick={() => setKey(0)}
          disabled={!user.authenticated}
        >
          <img src={Brain} width="30px" />
          <h5 className="mb-0">Collection</h5>
        </Button>
        <Button
          className={index === 1 ? "m-0 pl-3 active" : "m-0 pl-3"}
          variant="exo"
          onClick={() => setKey(1)}
        >
          <img src={Strong} width="30px" />
          <h5 className="mb-0">Entraînement</h5>
        </Button>
        <Button
          className={index === 2 ? "m-0 pl-3 active" : "m-0 pl-3"}
          variant="exo"
          onClick={() => setKey(2)}
        >
          <img src={Pencil} width="30px" />
          <h5 className="mb-0">Examen Blanc</h5>
        </Button>
        {user.authenticated && (
          <Button
            className={index === 3 ? "m-0 pl-3 active" : "m-0 pl-3"}
            variant="exo"
            onClick={() => setKey(3)}
          >
            <img
              src={user.data.profileImage}
              className="rounded-circle  "
              width="30px"
              height="30px"
            />
            <h5 className="mb-0">Profile</h5>
          </Button>
        )}
        {user.data.admin && (
          <>
            <Button
              className={index === 4 ? "m-0 pl-3 active" : "m-0 pl-3"}
              variant="exo"
              onClick={() => setKey(4)}
            >
              <img src={Ecole} width="30px" />
              <h5 className="mb-0">Admin</h5>
            </Button>
            {adminEmailList.admin.indexOf(user.data.email) !== -1 && (
              <Button
                className={index === 5 ? "m-0 pl-3 active" : "m-0 pl-3"}
                variant="exo"
                onClick={() => setKey(5)}
              >
                <img src={Member} width="30px" />
                <h5 className="mb-0"> Comptes</h5>
              </Button>
            )}
          </>
        )}
      </Stack>
    </Container>
  );
};
export default VerticalNavigation;
